import React, { useState } from "react";
import axios from "axios";
import {Gap, SubSectionContainer, TextArea} from '../style/MyTags'


export default function ChatGPT() {
	const [prompt, setPrompt] = useState("");
	const [response, setResponse] = useState("");
	const HTTP = "https://ar.maha.lol/chat";

	let instruct = 'Be as concise as possible.'

	const handleSubmit = () => {

		axios
		.post(`${HTTP}`, { prompt })
		.then((res) => {
			setResponse(removeLineBreaks(res.data.message));
			console.log(prompt);
		})
		.catch((error) => {
			console.log(error);
		});

	};

	function removeLineBreaks(text) {
		return text.replace(/^\n+|\n+$/g, '');
	  }

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // prevents new line
			handleSubmit()
		}
	};

	return (
		<SubSectionContainer>
			<TextArea
			// value={prompt}
			placeholder='Enter a prompt and press enter'
			onChange={e => {
				setPrompt(instruct + ' ' + e.target.value)
			}}
			onKeyDown={handleKeyPress}
			style={{marginRight: '20px', height: '300px'}}
			/>
			{/* <Gap px={20}/> */}
			<TextArea
			value={response}
			placeholder='Response will appear here'
			readOnly={true}
			style={{height: '300px'}}
			/>
		</SubSectionContainer>
	);
}