/* eslint-disable */

import React, {useState, useRef, useEffect} from 'react';
import styled, { keyframes } from 'styled-components'
import axios from "axios";
// import cv from 'opencv.js';



import { Section, Gap, MyTheme, PageContainer } from '../components/style/MyTags';
import { Arrow, ParentContainer, PanelFlexContainer, PanelTabButtonsContainer, PanelTabButton, PanelTitle, PanelGroupContainer, PanelInputContainer, PanelGroupTitle, PanelInputLabel, PanelInput, PanelInputColor, PanelButton , TextArea} from '../components/style/MyTags';

import {resizeImage} from '../utilities/Utilities'

import ParticleVisualiser from '../components/visualiser/ParticleVisualiser';
import ChatComponent from '../components/visualiser/Chat';
import LoadingModal from '../components/visualiser/LoadingModay'

import defaultImage from '../images/Mandala3.jpg'
import { presets } from '../components/visualiser/PresetsPV'

//presets = ['person', 'colourful']
let preset = 'colourful'
let mods = presets[preset]

const nPixels = {x: 64, y: 64}

console.log(mods)

let imgData

const chatDataInit= [
	{ user: 'AI', message: 'Tell me how to react to audio!' },
]

export default function Visualiser() {
	
	const [ready, setReady] = useState(false)
	const [react, setReact] = useState(false)
	const [clicked, setClicked] = useState(false)
	const [isControlPanelOpen, setIsControlPanelOpen] = useState(false);
	const [activeTab, setActiveTab] = useState('image');
	const [reactPrompt, setReactPrompt] = useState('');
	const [selectedImage, setSelectedImage] = useState(defaultImage);
	const [imagePrompt, setImagePrompt] = useState('');
	const [lights, setLights] = useState();
	const [chatData, setChatData] = useState([...chatDataInit]);
	const [inputs, setInputs] = useState(JSON.stringify(mods))

	const [isLoading, setIsLoading] = useState(true);


	const fileInputRef = useRef(null);

	useEffect(() => {

		getImageData();
		
	}, [])

	const getImageData = async () => {

		setReady(false)
		setIsLoading(true);

		// processImage()
		const imgCanvas = document.createElement('canvas');
		const imgContext = imgCanvas.getContext('2d');
		const resizedImage = await resizeImage(selectedImage, nPixels.x, nPixels.y);
		const img = new Image();
		img.src = resizedImage;
		img.onload = function () {
			imgCanvas.width = img.width;
			imgCanvas.height = img.height;
			imgContext.drawImage(img, 0, 0);
			imgData = imgContext.getImageData(0, 0, img.width, img.height).data; //image data in the form RGBA
			setReady(true)
			// setIsLoading(false);

			setTimeout(() => {
				setIsLoading(false);
			}, 5000);
		};
	};

	const handleChatGPTPrompt = (e) => {
		// console.log(e.target.value)
		if (e.key === 'Enter') {
			requestChatGPT(e.target.value)
			addChat('User',e.target.value)
			setReactPrompt('')
		} 
	}
	const handleImagePrompt= (e) => {
		// console.log(e.target.value)
		if (e.key === 'Enter') {
			requestDalle(e.target.value)
			setImagePrompt('')
		} 
	}

	const addChat = (user, message) => {
		let chat = {user: `${user}`, message:`${message}` }
		setChatData(prevArray => [...prevArray, chat]);
		// chatData.push(chat)
	}

	const requestDalle = (userInstruct) => {
	
		const HTTP = "http://localhost:8080/generate";
		let prompt = userInstruct

		console.log('image prompt:' + userInstruct);
	
		axios
		.post(`${HTTP}`, { prompt })
		.then((res) => {
			console.log(res.data)
			setSelectedImage(res.data);


			// // Create an image element
			// const image = new Image();
			// image.src = res.data;
	
			// // Set up an onLoad event listener to handle the image loading
			// image.onload = () => {
			// 	// Now the image is loaded and can be processed/displayed
			// 	setSelectedImage(image);
			// };



		})
		.catch((error) => {
			console.log(error.message);
		});
	
	};

	const requestChatGPT = (userInstruct) => {
	
		// const HTTP = "https://ar.maha.lol/chat";
		const HTTP = "http://localhost:8080/chat";
		let instruct = 'Based on the following user input modify the JSON object.  Additionally, replace the "message" value with a string describing the values changed to the user. Only output the modified JSON object, no other text. Make the message as conscise as possible. If there is no way to do what the user wants, state that in the objects message and return the object'
		let parameters = JSON.stringify(mods)
		let prompt = instruct + '\n' + 'user input:' + userInstruct + '\n' + parameters
	
		console.log('user input:' + userInstruct);
		console.log(mods);
	
		axios
		.post(`${HTTP}`, { prompt })
		.then((res) => {
	
			// console.log(removeLineBreaks(res.data)) 
			console.log(res.data) 
			mods = res.data
			addChat('AI',mods.message)

			if(react !== mods.react.on){
				// dance = mods.react.on
				// setReact(dance)
				toggleReactivity()
			}


		})
		.catch((error) => {
			console.log(error);
		});
	
	};
	
	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
		  const reader = new FileReader();
		  reader.onload = () => {
			setSelectedImage(reader.result);
		  };
		  reader.readAsDataURL(file);
		}
	};

	const handleSelectImage = () => {
		fileInputRef.current.click();
	};
	
	const handlePushImage = () => {
		// setIsControlPanelOpen(false)
		getImageData()
		
	}

	const toggleReactivity = () => {
		if(clicked === false) {setClicked(true)}
		mods.react.on = !react
		setReact(!react)
	}
	

	return (
		<PageContainer>
			{/* {isLoading ? (
				// Show loading screen while the component is loading
				<LoadingModal />
			) : ''} */}
			<ParentContainer >
				{ready ? 
				<ParticleVisualiser 
				imgData={imgData} 
				nPixels={nPixels}
				preset={mods}
				react={react}
				clicked={clicked}
				onVisualiserClick={toggleReactivity}
				/> : ''} 
				<Arrow
				open={isControlPanelOpen}
				onClick={(event) => {
					// event.stopPropagation()
					setIsControlPanelOpen(!isControlPanelOpen)
				}}
				/>
				<PanelFlexContainer open={isControlPanelOpen}>
					<PanelTitle>CONTROL</PanelTitle>

					<PanelTabButtonsContainer>
						<PanelTabButton 
						active={activeTab === 'image'}
						onClick={()=> setActiveTab('image')} >Image</PanelTabButton>
						<PanelTabButton 
						active={activeTab === 'reactivity'}
						onClick={()=> setActiveTab('reactivity')} >Reactivity</PanelTabButton>
						<PanelTabButton 
						active={activeTab === 'help'}
						onClick={()=> setActiveTab('help')} >Help</PanelTabButton>
					</PanelTabButtonsContainer>

					{activeTab === 'image' &&
					<PanelGroupContainer>
						<PanelInputContainer >
							<PanelGroupTitle>Image Generation</PanelGroupTitle>
							<CurrentImage src={selectedImage} />
							<PanelInput
								id="imagePrompt"
								type="text"
								placeholder='Describe the image you want to create'
								value={imagePrompt}
								onChange={(e) => setImagePrompt(e.target.value)}
								onKeyDown={handleImagePrompt}
							/>
							<input 
								type="file"
								accept="image/*"
								ref={fileInputRef}
								onChange={handleImageChange}
								style={{ display: 'none' }}
							/>
							<PanelButton onClick={handleSelectImage}>Select Image From Device</PanelButton>
							<PanelButton style={{ fontWeight: '5px' }} onClick={handlePushImage} >Use Image</PanelButton>
						</PanelInputContainer>
					</PanelGroupContainer>
					}

					{activeTab === 'reactivity' &&
					<PanelGroupContainer>
						<PanelInputContainer >
							<PanelGroupTitle>Reactivity</PanelGroupTitle>
							<ChatComponent chatData={chatData}
							/>
							<PanelInput
								id="reactivity"
								type="text"
								placeholder='Describe reactivity'
								value={reactPrompt}
								onChange={(e) => setReactPrompt(e.target.value)}
								onKeyDown={handleChatGPTPrompt}
							/>
						</PanelInputContainer>
					</PanelGroupContainer>
					}

					{activeTab === 'help' &&
					<PanelGroupContainer>
						<PanelInputContainer >
							<PanelGroupTitle>Reactivity</PanelGroupTitle>
							<TextArea
							value={inputs}
							// placeholder='Enter a prompt and press enter'
							onChange={e => {
								setInputs(e.target.value)
							}}
							onKeyDown={(e)=>{
								if (e.key === 'Enter') {
									e.preventDefault(); // prevents new line
									mods = inputs
								}
							}}
							style={{marginRight: '20px', height: '300px'}}
							/>
						</PanelInputContainer>
					</PanelGroupContainer>
					}

				</PanelFlexContainer>
			</ParentContainer>
		</ PageContainer>
	);
}

const CurrentImage = styled.img`
  width: 100%;
  /* height: 300px; */
  object-fit: cover; /* Maintain aspect ratio and cover container */
  border-radius: 4px;
`;


const SphereHolder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: calc(100vh - 65px); */
	height: 100vh;
	
	/* width: 80vw; */
	position: relative;
	/* max-height: 600px; */
	overflow: hidden;
`;

SphereHolder.displayName = 'SphereHolder'
