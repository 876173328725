import React from "react";
import styled, {keyframes} from "styled-components";

// try to chain animations together by add delay greater than previous animation

// export default function AnimationName(props) {
//     const textArray = (props.text).split('')

//     return (
//         <Box>
//             <AnimatedText>
//                 {textArray.map((item, index) => (
//                     <span key={index}>{item}</span>
//                 ))}
//             </AnimatedText>
//         </Box>
        
//     )
// }

export default function AnimationName(props) {

    return (
        // <Box>
            <AnimatedText>
                ANJ<span>U</span>MAN RAUT
            </AnimatedText>
        // </Box>
        
    )
}

// const animation = keyframes`
//   0% { opacity: 1; transform: translate(0px,0px) rotateY(2160deg) scaleX(3) scaleY(0.5);  }
//   40%, 95% {opacity: 1; transform: translate(0px,0px); filter: blur(0px) }
//   100% {opacity: 1; transform: translate(0px,0px) scaleX(3) scaleY(0.5); filter: blur(0px) }
// `

const animation = keyframes`
  0% { opacity: 1; transform:  rotateY(2160deg);  }
  100% {opacity: 1; filter: blur(0px) }
`

/* const Box = styled.div`
    display: inline-block;
    position: fixed;
    overflow: hidden;
    text-align: left;
    z-index: 1000000;
` */

const AnimatedText = styled.h1`
    display:inline-block;
    flex: 1;
    font-size: 2em;
    font-weight: bold;
    color: #fbdbb6;
    margin: 0px;
    padding-left: 10px; 
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.514);


    span {
        display:inline-block;
        min-width: 10px;
        animation-name: ${animation};
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    
`

