import React from "react";
import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router'; //trying using Link, might speed things up

import styled, { keyframes, css } from 'styled-components'
import { MyTheme, Gap } from '../style/MyTags'

import AnimationName from '../animation/AnimateName' ;
// import CubeDisplay from '../3d/CubeDisplay'
import headshot from '../../images/Mandala3.jpg'
import { GiHamburgerMenu } from 'react-icons/gi';


export default function Nav() {

	const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    function FloatingSidebar() {
      
        return (
            <SidebarWrapper open={isOpen} clicked={clicked} >
                <Sidebar >
                    <div onClick={toggleSidebar}>
                        <SidebarItem onClick={()=>{navigate('/')}}><H3>Particle Visualiser</H3></SidebarItem>
                        <SidebarItem onClick={()=>{navigate('/ai')} }><H3>AI & Machine Learning</H3></SidebarItem>
                        <SidebarItem onClick={()=>{navigate('/mandala-visualiser')} }><H3>Mandala Visualiser</H3></SidebarItem>

                    </div>
                    {/* <CubeHolder>
                        <CubeDisplay/>
                    </CubeHolder> */}
                </Sidebar>
            </SidebarWrapper>

        );
    }

    const toggleSidebar = (e) => {
        e.preventDefault()
        setClicked(true)
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* <Bar>
                <HeadshotCropper><Headshot src={headshot} /></HeadshotCropper>
                <AnimatedText >Particle Visualiser</AnimatedText>
            </Bar> */}
            <NavIcon
                open={isOpen}
                onClick={toggleSidebar} />
            {/* <Gap px={50}/> */}
            <FloatingSidebar/>
        </div>
    )
}

const CubeHolder = styled.div`
    width: '100%';
    height: '100vh';
    position: 'relative';
`;

const AnimatedText = styled.h1`
    display:inline-block;
    flex: 1;
    font-size: 2em;
    font-weight: bold;
    color: #fbdbb6;
    margin: 0px;
    padding-left: 10px; 
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.514);
`

const NavIcon= styled(GiHamburgerMenu)`
    flex: 0 0;
	position: absolute;
	font-size: 40px;
	top: 0;
	right: 0;
	margin: 5px 10px;
    z-index: 101;
    &:hover {
        color: ${MyTheme.color.btnHover};
    };
    color: ${props => props.open ? 'red' : 'white'};
    transform: ${props => props.open ? 'scale(0.8) rotateZ(90deg)' : ''};
    transition: transform 0.2s ease-out;
`

export const H3 = styled.h1`
	font-size: 1.2em;
	font-size: calc(1.0em + 0.3vw);
	color: whitesmoke;
	margin: 0;
	padding: 5px;
`

const HeadshotCropper = styled.div`
  width: 40px;
  height: 40px;
  margin: 5px;
  margin-left: 15px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`
const Headshot = styled.img`
  display: inline;
  max-width: 100%;
  /* max-height: 100%; */
  transform: translate(0, 0)
`

const Bar = styled.div`
    display: flex;
    position: fixed;
    overflow: hidden;
    z-index: 100;
    text-align: left;
    background-color: #1b1515a4;
    width: 100%;
    max-height: 50px;
`
const enterFromTopRight = keyframes`
  0% {
    transform: translate(100%, -100%) scale(0.1) skewY(-60deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1) skewY(0deg);
    opacity: 1;
  }
`;

const exitToTopRight = keyframes`
  0% {
    transform: translate(0, 0) scale(1) skewY(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(100%, -100%) scale(0.1) skewY(-60deg);
    opacity: 0;
  }
`;

const getAnimation = (open, clicked) => {
    if(clicked){
        if (open) {
            return css`animation: ${enterFromTopRight} 0.5s ease-out forwards;`;
        } else {
            return css`animation: ${exitToTopRight} 0.5s ease-in forwards;`;
        }
    }
};

const SidebarWrapper = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  visibility: ${props => props.clicked ? 'visible' : 'hidden'};
  ${props => getAnimation(props.open, props.clicked)};
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 80%;
  height: calc(90vh - 50px);
  /* padding: 16px; */
  background-color: ${MyTheme.bgColor.section};
  z-index: 9999;
  box-shadow: 0 0 10px 5px rgb(0, 0, 0);

`;

const SidebarItem = styled.button`
    flex: 1;
    /* align-self: flex-start; */
    width: 100%;
    height: 60px;
    /* margin-bottom: 10px; */
    background-color: ${MyTheme.bgColor.item};
    color: ${MyTheme.color.h3};

    &:hover {
        background-color: ${MyTheme.bgColor.page};
    }
    &:hover ${H3}{
        color: ${MyTheme.color.navHover};
    }
`
