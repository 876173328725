import { round} from 'mathjs';

// import defaultSong from '../../audio/OverdueNolai.mp3'

//for 2048 sub:5-2 kick:28-2 snare:80-40 hat:164 vocal: 15-35-45
// let amplifiers = [1.2, 1, 1.1, 1.2, 1.3]
// let amplifiersDefault = [5, 5, 5, 5, 10]
// let amplifiers = [...amplifiersDefault]
// let analyseMode = 'max'

export class AudioAnalyzer {
    constructor(numBins, analyseMode = 'max') {
    this.fftSize = 2048;
    this.freqBins = this.calculateFreqBins(numBins);
    this.amplifiersDefault = {start: 5, end: 10}
    this.amplifiers = this.fillLogArray(this.amplifiersDefault.start, this.amplifiersDefault.end, numBins)
    this.freqBinsDefault = [[0, 1], [2, 30], [31, 50], [51, 100], [101, 1023]];
	this.nBins = numBins;
    this.audioContext = null;
    this.source = null;
    this.analyser = null;
    this.bufferLength = null;
    this.dataArray = null;
    this.gotMedia = false;
    this.musicPaused = true;
    this.freqAverages = [];
    this.freqAveragesNormalised = [];
    this.analyseMode = analyseMode; //glocally set here or as an input in 'getFrequency'
    this.mediaStream = null;
    this.audio = null;
    }

    updateParameters(nBins) {
		this.nBins = nBins;
        this.freqBins = this.calculateFreqBins(nBins);
        this.amplifiers = this.fillLogArray(this.amplifiersDefault.start, this.amplifiersDefault.end, nBins)
    }

    calculateFreqBins(nBins) {
        let nPoints = this.fftSize / 2;
        let maxExp = Math.log10(nPoints);
        let binSizeExp = maxExp / nBins;
        let freqBins = [];

        for (let i = 0; i < nBins; i++) {
            let start;
            if (i === 0) {
                start = 0;
            } else {
                start = Math.floor(Math.pow(10, binSizeExp * i));
            }
            let end = Math.floor(Math.pow(10, binSizeExp * (i + 1)) - 1);
            freqBins.push([start, end]);
        }

        // Include the remaining values in the last bin
        freqBins[nBins - 1][1] = nPoints - 1;
        
        return freqBins;
    }

    fillLogArray(start, end, numPoints) {
		const arr = [];
	  
		const logStart = Math.log10(start);
		const logEnd = Math.log10(end);
	  
		const increment = (logEnd - logStart) / (numPoints - 1);
	  
		for (let i = 0; i < numPoints; i++) {
            const x = Math.pow(10, logStart + (i * increment));
            arr.push(x);
		}
	  
		return arr;
	}

    //gets the averages of the frequencies
    getFrequency(analyseMode = this.analyseMode) {

        this.analyser.getByteFrequencyData(this.dataArray);

        if (analyseMode === "average") { // averages the frequency bin
            this.freqAverages = this.freqBins.map((range) => {
            let [start, end] = range;
            let valuesInBin = this.dataArray.slice(start, end + 1);
            let sum = valuesInBin.reduce((acc, curr) => acc + curr, 0);
            return round(sum / valuesInBin.length);
            });
        } else { // gets the max from the frequency bin
            this.freqAverages = this.freqBins.map((range) => {
            let [start, end] = range;
            let valuesInBin = this.dataArray.slice(start, end + 1);
            return Math.max(...valuesInBin);
            });
        }

		if (this.checkSilence()){ // if silence then reset amplifiers
            this.amplifiers = this.fillLogArray(this.amplifiersDefault.start, this.amplifiersDefault.end, this.nBins)
        }

		return this.freqAverages;

    }


	//auto optimise amplifier so that it reaches the max value
	normaliseVal(val, maxVal, minTargetValue, maxTargetValue, type='none', amplifier=1, index=null) {

		let scaledVal
		if (minTargetValue <= maxTargetValue) {
			scaledVal = (minTargetValue + (val / maxVal) * (maxTargetValue - minTargetValue)) * amplifier
		} else {
			scaledVal = (maxTargetValue - (val / maxVal) * (minTargetValue - maxTargetValue)) * amplifier
		}

		//auto optimise amplifier
		if(scaledVal > (maxTargetValue)) {
			if (index != null) {

				let newAmplifier = round(amplifier * maxTargetValue / scaledVal, 2)
				this.amplifiers[index] = newAmplifier
				// console.log(index, scaledVal)
				// console.log(this.amplifiers)
			}
			scaledVal = maxTargetValue
		}

		if(type === 'none') {
			return 	round(scaledVal)
		} else if (type === 'log') {
			let newScaledVal = this.logarithmicScaling(scaledVal, minTargetValue, maxTargetValue)
			// if (maxTargetValue === 100) console.log(scaledVal, newScaledVal, minTargetValue, maxTargetValue)
			return round(newScaledVal)
		}

	}

	logarithmicScaling(value, minVal, maxVal) {
		if (minVal <= 0) {
		//   minVal = Number.EPSILON; // set minVal to smallest possible positive value
		  minVal = 1; // set minVal to smallest possible positive value
		}
		const minValue = Math.log(minVal);
		const maxValue = Math.log(maxVal);
		const scale = (maxValue - minValue) / (maxVal - minVal);
		let newVal = Math.exp(minValue + scale * (value - minVal));
		return round(newVal)
	}

    checkSilence(freqAverages = this.freqAverages){
        if (freqAverages.every((num) => num === 0)) {
            return true
        }
        return false
    }

    async getAudio(musicSource, song) {
        if (musicSource === "microphone") {
            try {
            this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.audioContext = new AudioContext();
            this.source = this.audioContext.createMediaStreamSource(this.mediaStream);
            this.analyser = this.audioContext.createAnalyser();
            this.analyser.fftSize = this.fftSize;
            this.source.connect(this.analyser);
            this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
            this.gotMedia = true;
            } catch (error) {
            console.error("Error getting user media: ", error);
            }

            if (this.gotMedia) {
                return { gotMedia: true, dataArray: this.dataArray };
            } else {
                return { gotMedia: false };
            }
        } else {
            this.audio = document.createElement("audio");
            this.audio.src = song;
            this.gotMedia = true;
            this.audioContext = new AudioContext();
            this.source = this.audioContext.createMediaElementSource(this.audio);
            this.source.connect(this.audioContext.destination)
            this.analyser = this.audioContext.createAnalyser();
            this.analyser.fftSize = this.fftSize;
            this.source.connect(this.analyser)

            this.dataArray = new Uint8Array(this.analyser.frequencyBinCount)
            return { gotMedia: true, dataArray: this.dataArray }
        }
    }

    playAudio() {
        this.audio.play()
    }

    pauseAudio() {
        this.audio.pause()
    }
}