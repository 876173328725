export const presets = {

    person: { 
	    particleShape: 'cube', //available 'cube', 'rectangle'
        profile: {
            x: 'none', 
            y: 'none', 
            z: 'brightness'
        }, 
        scale: {
            on: true, 
            property:'brightness', 
            range: {min: 0.8, max: 3}
        }, 
        zDepth: {
            static: 10, 
            animated: 15, 
            dance:20
        }, 
        react: {
            scaleDimension: {
                on: true,
                dimension: 'z'
            },
            position: {
                on: false,
                dimension: 'z'
            }
        },
        rotate: {
            on: true, 
            speed:{x:0.02, y: 0.02, z: 0.02}
    }},

    colourful: {
        message: 'Enter your message here.',
	    particleShape: 'cube', //available 'cube', 'sphere', 'circle', 'octahedron', 'star'
        profile: {
            x: 'none', 
            y: 'none', 
            z: 'hue'
        }, 
        scale: {
            on: true, 
            property:'brightness', 
            range: {min: 0.8, max: 2},
            dance: {min: 1, max: 3}
        }, 
        zDepth: {
            static: 24, 
            animated: 20, 
            dance: 40
        }, 
        react: {
            on: false,
            scaleDimension: {
                on: true,
                dimension: 'z'
            },
            scaleAll: {
                on: true,
            },
            position: {
                on: false,
                dimension: 'z'
            }
        },
        colorLoop: {
            on: true,
            speed: 0.0001
        },
        colorPivot: {
            on: true,
            delta: 0.5 // proportion of color wheel
        },
        rotate: {
            on: false, 
            speed:{x:0.02, y: 0.02, z: 0.02}  
        },
        glow: {
            on: false,
            strength: 0.4,
            radius: 1,
            threshold: 0,
        },
        randomness: {
            on: true,
            amount: 0.05, //proportion of actual value
        },
        lights: [ // where x,y,x is provided in terms of the center which is (1,1,1)
            {name: "frontTopRight", x: 1.3, y: 2, z: 1, intensity: 2, colour: 'white' },
            {name: "backTopBottom", x: 1, y: 3, z: -1, intensity: 4, colour: 'red' },
            // {name: "frontBottomCenter", x: 1, y: -2, z: 1, intensity: 2, colour: 'white' },
            {name: "frontBirdRight", x: 2, y: 4, z: 0, intensity: 2, colour: 'white' },
            {name: "frontBirdLeft", x: 0, y: 4, z: 0, intensity: 2, colour: 'white' },
            {name: "frontLowLeft", x: 1, y: -2, z: 0, intensity: 2, colour: 'white' },
        ]

    }


}