
export const resizeImage = (image, w, h) => {
	return new Promise((resolve, reject) => {
		// Create an Image object
		const imageTest = new Image();

		// Set the source of the image
		imageTest.src = image;

		// Wait for the image to load
		imageTest.onload = function() {
			const canvas = document.createElement('canvas');
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext('2d');

			// Draw the image onto the canvas
			ctx.drawImage(imageTest, 0, 0, w, h);

			// Get the resized image as a base64-encoded string
			const resizedImage = canvas.toDataURL('image/jpeg');
			// console.log(resizedImage)

			// Resolve the promise with the resized image
			resolve(resizedImage);
		};

		// Handle errors if the image fails to load
		imageTest.onerror = function() {
			reject(new Error('Failed to load image'));
		};
	});
};

