/* eslint-disable */

import './App.css';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'

import Nav from './components/nav/Nav';

//screens
import ParticleVisualiser from './screens/VisualiserScreen';
import AIScreen from './screens/AIScreen';
import MandalaScreen from './screens/MandalaScreen';


//IMPORTANT TASKS-------------------------------------
// change all delta values so that it is based on time
// add save button which saves to local storage
// add loading screens
// add movement to the camera
// add a tab for audio
// add controls for position of camera and direction
// add local storage functionality so it saves the image and preset values
// do smoothing function only once at the beginning to get initial z depths
// add a way to refer to the different audio bins by the corresponding frequency e.g. "bass"
// add text infront of scene
// when image is updated, create a function which removes cubes and add new cubes. This will prevent whole scene from being rendered again.
// upgrade to chatGPT 3.5 because it allows an introduction prompt (system message)
// to update lights maybe use a useState array 
// reorganise preset structure to increase ChatGPT accuracy. If changing object names doesn't work then manually write descriptions and also send that to chatGPT.
// use a different language model which does chat history. send the introduction request at the very beginning
// calculate the most common color then do actions on it
// change movement parameters based on colour

// DONE create chat
// DONE possibly have control panel on the screen component instead of the visaliser component
// DONE move all react logic to screen component

//REQUIRED TASKS BUT NOT URGENT
//use AI libraries to detect what is in the image so the correct preset type can be applied

function AppContainer() {


  return (
	<div className='App'>

		{/* //navigation bar and floating sidebar */}
    	<Nav /> 

    	<Routes>
			<Route path ='/' element={<ParticleVisualiser />}></Route>
			<Route path ='/ai' element={<AIScreen />}></Route>
			<Route path ='/mandala-visualiser' element={<MandalaScreen />}></Route>
		</Routes>

	</div>
  );
}

function App() {

	// console.disableYellowBox = true;
	return (
		<>
			<BrowserRouter>
				<AppContainer />
			</BrowserRouter>
		</>

	);
};

export default App;
