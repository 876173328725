import React, { useState, useRef, useEffect } from 'react';
import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
// import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

// import { MyTheme } from '../style/MyTags';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { random, round } from 'mathjs';

// import { presets } from './PresetsPV';
import { AudioAnalyzer } from './AudioAnalyser'
import song from '../../audio/OverdueNolai.mp3'

let mods
let colourProp = {
	hue: { max: 360 },
	saturation: { max: 100 },
	brightness: { max: 100 }
}

let gridSize

let objects = []; //holds all the visual objects
let scene
let imgMatrix = [[]]; //holds all the visual objects
const objectSize = 1;
const spacing = 1;

let dColour = 0

let audioAnalyser
let analyseMode = 'max'
let nBins = 10
let musicSource = 'microphone'
let gotMedia = false
let musicSet = false
let dance = false
let freqAverages
let freqAverage 
let freqAverageMax = 255

var startTime = Date.now();

const processImageData = () => {

}
export default function ParticleVisualiser({imgData, nPixels, preset, react, clicked, onVisualiserClick}) {

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		mods = preset
		
	}, [preset])
	dance = react
	gridSize = nPixels.x

	const sceneRef = useRef(null);
	const rendererRef = useRef(null);
	const controlsRef = useRef(null);
	const containerRef = useRef(null);

	// requestChatGPT() //SEND INTRODUCTION REQUEST

	// const [react, setReact] = useState(false)
	// dance = mods.react.on

	// main 
	useEffect(() => {

		// processImageData()

		sketch()

		// Clean up on unmount
		return () => {
			if (controlsRef.current) {
				controlsRef.current.dispose();
			}
			if (sceneRef.current) {
				sceneRef.current.clear();
			}
			if (rendererRef.current) {
				rendererRef.current.dispose();
			}
			if (containerRef.current && rendererRef.current && rendererRef.current.domElement) {
				containerRef.current.removeChild(rendererRef.current.domElement);
			}
			// cancelAnimationFrame(animate)
		};

	}, []);

	// draw three.js objects
	const sketch = () => {

		const container = containerRef.current;
		const dimension = Math.min(container.clientWidth, container.clientHeight)

		// Set up the scene, camera, and renderer
		scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera();
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setSize(dimension, dimension);
		containerRef.current.appendChild(renderer.domElement);

		// Create an instance of OrbitControls
		const controls = new OrbitControls(camera, container);
		controls.enableZoom = true;
		controls.enablePan = true;
		controls.enableRotate = true;
		controls.screenSpacePanning = true;
		controls.zoomToCursor = true;
		// controls.autoRotate = true;
		// controls.autoRotateSpeed = -10;
		controls.enableDamping = true

		// Save references to the scene and renderer
		sceneRef.current = scene;
		rendererRef.current = renderer;
		controlsRef.current = controls;

		// scene.background = new THREE.Color('#cecece');
		scene.background = new THREE.Color('black');
		// scene.background = new THREE.Color(`${MyTheme.bgColor.section}`);

		let composer, bloomPass
		if(mods.glow.on) {

			// Add bloom effect
			bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight));
			bloomPass.strength = mods.glow.strength
			bloomPass.radius = mods.glow.radius
			bloomPass.threshold = mods.glow.threshold

			// outputPass = new OutputPass( THREE.ReinhardToneMapping );
			
			composer = new EffectComposer(renderer);
			composer.addPass(new RenderPass(scene, camera));
			composer.addPass(bloomPass);
			// composer.addPass(outputPass);
			
			// renderer.toneMapping = THREE.CineonToneMapping
			// renderer.toneMappingExposure = 1.5
		}

		// Create the grid of objects
		createParticleGrid()

		// // Create an array of Vector3 points
		// var points = [
		// 	new THREE.Vector3(0, 0, 0),
		// 	new THREE.Vector3(0, 100, 0),
		// 	new THREE.Vector3(100, 100, 0),
		// 	new THREE.Vector3(100, 0, 0)
		// ];
		
		// // Create a geometry and set the points
		// var geometry = new THREE.BufferGeometry().setFromPoints(points);
		
		// // Create a material for the shape
		// var material = new THREE.MeshBasicMaterial({ color: 'black' });
		
		// // Create a mesh using the geometry and material
		// var object = new THREE.Mesh(geometry, material);

		// objects.push(object);
		// scene.add(object);

		// console.log(object)

		// Position the camera
		const gridWidth = gridSize * (objectSize + spacing);
		const gridHeight = gridSize * (objectSize + spacing);
		const gridDepth = objectSize; //make this max of zDepth
		const maxGridSize = Math.max(gridWidth, gridHeight, gridDepth);
		const gridCenterX = (gridSize) * (objectSize + spacing) / 2
		const gridCenterY = (gridSize) * (objectSize + spacing) / 2
		const gridCenterZ = maxGridSize / 2
		console.log(gridWidth, gridHeight, gridDepth)
		console.log(gridCenterX, gridCenterY, gridCenterZ)

		// look here
		controls.target.set(gridCenterX, gridCenterY, 0); 
		// from here
		// camera.position.set(gridWidth, gridCenterY * 0.8, gridCenterZ * 2.5); // mid right
		// camera.position.set(gridCenterX, - gridCenterY, gridCenterZ * 0.75); // bottom center
		camera.position.set(gridCenterX, gridCenterY, gridCenterZ * 2.5); // mid center
		// point the camera
		camera.lookAt(new THREE.Vector3(gridCenterX, gridCenterY, 0)); 

		// Point lights for visibility
		mods.lights.forEach(light => {
			let newLight = new THREE.PointLight(light.colour, light.intensity);
			newLight.position.set(gridCenterX * light.x, gridCenterY * light.y, gridCenterZ * light.z);
			scene.add(newLight);
		});

		// repeats every frame
		const animate = () => {
			requestAnimationFrame(animate);

			var currentTime = Date.now(); // Get the current timestamp in milliseconds
			var elapsedTime = currentTime - startTime; // Calculate the elapsed time
			
			//gets audio data for each frame
			if(dance && gotMedia) freqAverages = audioAnalyser.getFrequency(analyseMode)

			if (!dance) loopParameter(objects,'position.z', 0, mods.zDepth.animated, 0.1,'zDirection')

			//repeats every frame for every object
			objects.forEach(object => {

				if(mods.rotate.on){ // rotates on all axis
					object.rotation.x += (mods.rotate.speed.x * object.userData.rotationDirection)
					object.rotation.y += (mods.rotate.speed.y * object.userData.rotationDirection)
					object.rotation.z += (mods.rotate.speed.z * object.userData.rotationDirection)
				}
				
				if(mods.scale.on){ // scales all dimensions
					let updatedValue = animateProperty(object.scale.x,mods.scale.range.min, mods.scale.range.max, 0.01, object.userData.scaleDirection)
					object.scale.setScalar(updatedValue.value)
					object.userData.scaleDirection = updatedValue.direction
				}

				if(mods.colorLoop.on){ // loops through all colors
					let hue = object.userData.hue + mods.colorLoop.speed * elapsedTime
					if(hue > 1) hue = hue - 1
					object.material.color.setHSL(hue,object.userData.saturation,object.userData.brightness)
				}

				// react every frame for every object based on audio
				if(dance && gotMedia){
					let displacement
					freqAverage = freqAverages[object.userData.audioBin]
					let freqProp = freqAverage / freqAverageMax
					// console.log(freqAverages)
					
					if(mods.react.position.on) { // shifts position 
						displacement = freqProp * mods.zDepth.dance
						displacement = displacement - object.userData.zDepthRandom
						mods.react.position.dimension === 'z' 
						? 	object.position[mods.react.position.dimension] = displacement 
						: 	object.position[mods.react.position.dimension] = (displacement + object.userData[mods.react.position.dimension])/2 //make it so you can select x,y,z
						// object.position.z = (displacement + object.position.z) / 2 //average with old value
					}

					if(mods.react.scaleAll.on) { // scales all dimensions
						displacement = freqProp * mods.scale.dance.max
						if (displacement > mods.scale.dance.min) {
							object.scale.setScalar(displacement)
						} else {object.scale.setScalar(mods.scale.dance.min)}
					}

					if(mods.react.scaleDimension.on) { //scales selected dimension
						displacement = freqProp * mods.zDepth.dance
						displacement = displacement - object.userData.zDepthRandom
						if (displacement > 1) {
							object.scale[mods.react.scaleDimension.dimension] = displacement 
						} else {object.scale.z = 1}
					}

					if(mods.colorPivot.on) {
						let hue = object.userData.hue + mods.colorPivot.delta * freqProp
						if(hue > 1) hue = hue - 1
						object.material.color.setHSL(hue,object.userData.saturation,object.userData.brightness)
					}

					// console.log(object.userData.audioBin)
				}

			}) //repeats every frame for every object ---------------------------

			dColour += 1

			renderer.setSize(dimension, dimension);
			controls.update();
			if(mods.glow.on){
				composer.render();
			} else {
				renderer.render(scene, camera);
			}
		}; // repeats every frame-------------------------------------------------

		setTimeout(() => {
			setIsLoading(false);
		}, 10000);

		animate();


		
	} //end of sketch()--------------------------------------------------------------------------------

	const createParticleGrid = () => {
		let x, y, z, ix, iy, idx, r, g, b;
	
		for (let i = 0; i < gridSize; i++) {
			for (let j = 0; j < gridSize; j++) {
	
				//2d positions of objects
				x = i * (objectSize + spacing);
				y = j * (objectSize + spacing);
	
				//process colour by traversing 1D pixel data array from img like a 2D matrix
				let color = new THREE.Color();
				ix = Math.floor((i / gridSize) * nPixels.x)
				iy = Math.floor((((gridSize - 1) - j) / gridSize) * nPixels.y)
				idx = (iy * nPixels.y + ix) * 4; //4 used because imgData returned as RGBA
				r = imgData[idx + 0];
				g = imgData[idx + 1];
				b = imgData[idx + 2];
				color.setRGB(r/255, g/255, b/255)
				let hsvArray = rgbToHsv(r, g, b);
				let colorHSV = {
					hue: hsvArray[0],
					saturation: hsvArray[1],
					brightness: hsvArray[2]
				};
				if(colorHSV.brightness < 20) continue // skip drawing the object
	
				//define object geometry
				// const geometry = new THREE.BoxGeometry(objectSize, objectSize, objectSize);
				const geometry = createGeometry(mods.particleShape, objectSize, colorHSV)
	
				//assign material
				const material = new THREE.MeshPhongMaterial({ color: color});
				// const material = new THREE.MeshBasicMaterial({ color: color});
				const object = new THREE.Mesh(geometry, material);
	
				//position object (initial)
				object.position.x = x
				object.position.y = y
				if(mods.profile.z === 'hue'){
					z = mods.zDepth.static * normaliseVal(colorHSV[mods.profile.z], colourProp[mods.profile.z].max, 0, 1, false)
				} else if (mods.profile.z === 'brightness'){
					z = mods.zDepth.static * normaliseVal(colorHSV[mods.profile.z], colourProp[mods.profile.z].max, 0, 1, false)
				} else if (mods.profile.z === 'saturation'){
					z = mods.zDepth.static * normaliseVal(colorHSV[mods.profile.z], colourProp[mods.profile.z].max, 0, 1, false)
				}
				if(mods.randomness.on) {
					object.userData.zDepthRandom = randomiseValue(z, 0, mods.randomness.amount)
					z -= object.userData.zDepthRandom
				} else {
					object.userData.zDepthRandom = 0
				}
				object.position.z = z
	
				//scale object (initial)
				let scaleInit = normaliseVal(colorHSV[mods.scale.property], colourProp[mods.profile.z].max, mods.scale.range.min, mods.scale.range.max, false)
				object.scale.set(scaleInit, scaleInit, scaleInit)
	
				//create new variables for visual object
				// var init = {
				// 	pos: {x: x, y: y, z: object.position.z},
				// 	color: colorHSV
				// }
				// object.userData.init = init
				object.userData.x = x
				object.userData.y = y
				object.userData.z = z
				object.userData.hue = colorHSV.hue / colourProp.hue.max
				object.userData.brightness = colorHSV.brightness / colourProp.brightness.max
				object.userData.saturation = colorHSV.saturation / colourProp.saturation.max
				
				object.userData.zDirection = 1;
				if (colorHSV.hue < (colourProp.hue.max / 2)) object.userData.zDirection = -1
				object.userData.scaleDirection = 1;
				object.userData.rotationDirection = 1;
				if(colorHSV.brightness < 80) object.userData.rotationDirection = -1
				// object.userData.audioBin = round(random(nBins-1))
				object.userData.audioBin =  round((nBins -1) * normaliseVal(colorHSV[mods.profile.z], colourProp[mods.profile.z].max, 0, 1, false))
				// console.log(object.userData.audioBin, nBins)
				objects.push(object);
				scene.add(object);
			}
		}
	}

	//transition between reactivity
	useEffect(() => {
		// console.log(`dance is ${dance}`)
		dance = react

		if(dance){
			//prepare for reacting
			mods.scale.on = false
			mods.rotate.on = false
			objects.forEach(object => {
				object.position.z = 0 
				object.rotation.x = 0
				object.rotation.y = 0
				object.rotation.z = 0
				object.scale.setScalar((objectSize + spacing) / objectSize)
			});
			mods.colorLoop.on = false
		} else {
			//reset to defaults
			mods.scale.on = true
			mods.rotate.on = true
			mods.colorLoop.on = true
			objects.forEach(object => {
				object.position.x = object.userData.x
				object.position.y = object.userData.y
				object.position.z = object.userData.z
			});
			
		}
		
		if(clicked){
			// musicState()
		}
		// musicState()
		console.log('clicked is ' + clicked)
		console.log('dance is ' + dance)

	}, [react]);



	const setAudio = () => {
		audioAnalyser = new AudioAnalyzer(nBins, analyseMode)
		gotMedia = false
		audioAnalyser.getAudio(musicSource, song)
		.then(data => {
			gotMedia = data.gotMedia; // true if media was successfully captured, false otherwise
		})
		.catch(error => {
			console.error('Error: ', error);
		});
	}
	
	const musicState = () => {
		console.log('music state called')

		if(musicSet === false){ 
			musicSet = true
			setAudio()
			console.log('audio set')

		}

		if(musicSource === 'inbuilt'){
			if(dance === false){
				audioAnalyser.pauseAudio()
				console.log('pause audio')
			} else {audioAnalyser.playAudio()}
		} 

		// dance = !dance
		// mods.react.on = dance
		// setReact(!react)

	}	

	const handleClick = () => {
		musicState()
	}
	
	return (
		<>
			<div
			style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}
			ref={containerRef} 
			onMouseUp={handleClick}
			onClick={onVisualiserClick}
			/>

		</>
	);
}; // end of ParticleVisualiser component



function removeLineBreaks(text) {
	return text.replace(/^\n+|\n+$/g, '');
}

function randomiseValue(originalValue, lowerBound, upperBound) {
	// Calculate the new lower and upper bounds
	var newLowerBound = originalValue * lowerBound;
	var newUpperBound = originalValue * upperBound;
  
	// Generate a random number between the new lower and upper bounds
	var randomNumber = random(newLowerBound, newUpperBound);
  
	return randomNumber;
}

const scaleObjects = (objects, property, minScale, maxScale, stepSize, direction) => {
	objects.forEach(object => {
		const properties = property.split('.'); // Split the layered property by dot (.)
		let currentProperty = object;
	
		// Traverse the object to access the nested property
		for (const prop of properties) {currentProperty = currentProperty[prop]}
		
		let updatedValue;
		if (direction === undefined || direction === null) {
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize);
		} else {
			const scaleDirection = object.userData[direction];
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize, scaleDirection);
			object.userData[direction] = updatedValue.direction;
		}
		object.scale.setScalar(updatedValue.value)
	});
};

const loopParameter = (objects, property, minScale, maxScale, stepSize, direction) => {
	objects.forEach(object => {
		const properties = property.split('.'); // Split the layered property by dot (.)
		let currentProperty = object;
	
		// Traverse the object to access the nested property
		for (const prop of properties) {currentProperty = currentProperty[prop]}
		
		let updatedValue;
		if (direction === undefined || direction === null) {
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize);
		} else {
			const scaleDirection = object.userData[direction];
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize, scaleDirection);
			object.userData[direction] = updatedValue.direction;
		}

		// Update the nested property with the new value
		let targetProperty = object;
		for (let i = 0; i < properties.length - 1; i++) {
			targetProperty = targetProperty[properties[i]];
		}
		targetProperty[properties[properties.length - 1]] = updatedValue.value;
	});
};

const animateProperty = (property, minValue, maxValue, stepSize, direction) => {
	let value = property;
	let newDirection = direction;
  
	if (direction === undefined || direction === null) {
	  value += stepSize;
	  if (value > maxValue) {
		value = minValue;
	  }
	} else {
	  value += stepSize * direction;
	  if (value > maxValue || value < minValue) {
		newDirection = -direction;
		value = Math.max(minValue, Math.min(maxValue, value));
	  }
	}
  
	return { value, direction: newDirection };
};

const rotate = (objects, x=0.01, y=0.01, z=0) => {
	objects.forEach(object => {
		object.rotation.x += (x * object.userData.rotationDirection)
		object.rotation.y += (y * object.userData.rotationDirection)
		object.rotation.z += (z * object.userData.rotationDirection)
	})
}
const createGeometry = (shape, objectSize, colorHSV) => {

	let geometry
	if (shape === 'cube') {
		geometry = new THREE.BoxGeometry(objectSize, objectSize, objectSize);
	}else if (shape === 'sphere') {
		geometry = new THREE.SphereGeometry(objectSize / 2, 8, 8);
	}else if (shape === 'star') {
		geometry = new THREE.SphereGeometry(objectSize / 2, 3, 16, 0, 2 * Math.PI, 0, 2 * Math.PI);
	}else if (shape === 'cylinder') {
		geometry = new THREE.CylinderGeometry(objectSize / 2,objectSize / 2,1,8)
	}else if (shape === 'pyramid') {
		const radius = (objectSize * Math.sqrt(3)) / 3; // Calculate the radius for an equilateral triangle
		const height = (2 * objectSize) / Math.sqrt(3); // Calculate the height for an equilateral triangle
		geometry = new THREE.ConeGeometry(radius, height, 9); // Specify the number of segments as 3 for a triangular pyramid
	}else if (shape === 'circle') {
		geometry = new THREE.CircleGeometry(objectSize / 2);
	}else if (shape === 'square') {
		geometry = new THREE.PlaneGeometry(objectSize, objectSize);
	}else if (shape === 'octahedron') {
		geometry = new THREE.OctahedronGeometry(objectSize / 2, 0);
	}else if (shape === 'roundedBox') {
		geometry = createRoundedBox(objectSize, objectSize, objectSize, 0.2 * objectSize, 16);
	}

	return geometry
}


function createRoundedBox(width, height, depth, radius, segments) {
	const shape = new THREE.Shape();
	const eps = 0.00001;

	shape.absarc( eps, eps, eps, -Math.PI / 2, -Math.PI, true );
	shape.absarc( eps, height -  radius * 2, eps, Math.PI, Math.PI / 2, true );
	shape.absarc( width - radius * 2, height -  radius * 2, eps, Math.PI / 2, 0, true );
	shape.absarc( width - radius * 2, eps, eps, 0, -Math.PI / 2, true );

	const geometry = new THREE.ExtrudeGeometry( shape, {
		depth: depth - radius * 2,
		bevelEnabled: true,
		bevelSegments: segments,
		steps: 1,
		bevelSize: radius,
		bevelThickness: radius,
		curveSegments: segments
	});

	geometry.center();

	return geometry;
  }
  
  // need to scatter in different direction from the center
const scatterObjects = (objects, minScale, maxScale, stepSize) => {
	objects.forEach(object => {
		object.position.x += stepSize
		object.position.y += stepSize
		if(object.position.x >= (object.userData.xInit * maxScale)) {
			object.position.x = object.userData.xInit * minScale
			object.position.y = object.userData.yInit * minScale
		}
	});
}

const loadImage = async (url) => {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = url
		img.onload = () => resolve(img)
		img.onerror = () => reject()
	})
}



function rgbToHsv(r, g, b){
    r /= 255
	g /= 255
	b /= 255

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, v = max;

    let diff = max - min;
    s = max === 0 ? 0 : diff / max;

    if(max === min){
        h = 0; // achromatic
    } else {
        switch(max){
            case r: h = (g - b) / diff + (g < b ? 6 : 0); break;
            case g: h = (b - r) / diff + 2; break;
            case b: h = (r - g) / diff + 4; break;
			default: h = 0; break;
        }
        h /= 6;
    }

	v *= 0.7; // Adjust the brightness factor here (e.g., 0.8 for 80% brightness)


    return [ h * 360, s * 100, v * 100 ];
}

function normaliseVal(val, maxVal, minTargetValue, maxTargetValue, reverse = false) {
	let scaledVal
	if (minTargetValue <= maxTargetValue) {
		scaledVal = (minTargetValue + (val / maxVal) * (maxTargetValue - minTargetValue))
	} else {
		scaledVal = (maxTargetValue - (val / maxVal) * (minTargetValue - maxTargetValue))
	}
	if(reverse === true) return minTargetValue + maxTargetValue - scaledVal
	return 	scaledVal

}