import styled, { keyframes } from 'styled-components'
import {MdOutlineSwipe} from 'react-icons/md';

export const MyTheme = {
  	bgColor: {
		page: '#202025', 
		section: 'rgba(40,42,53,0.9)', 
		sectionSolid: 'rgba(40,42,53)', 
		item: '#414455',
		select: 'black',
	}, 
  	color: {
		h1: 'white', //AnimatedHeading
		h2: 'white', 
		h3: '#fbdbb6', 
		h3Hover: '#e1ba8d', 
		btnHover: '#fa2720',
		navHover: '#fa2720',
		button: 'white',
		text: 'white',
		select: 'white',
		bounce: 'white'
	},
	shadow: {
		h1: 'rgba(255, 255, 255, 0.514)',
		text: 'rgba(255, 255, 255, 0.514)',
		bounce: 'rgba(255, 255, 255, 0.514)'
	}
}

export const PageContainer = styled.div`

	background-color: ${MyTheme.bgColor.page};
	/* min-height: 100vh; */
	width: 100%;
	height: 100%;
	box-sizing: border-box;
`

const enterFromRight = keyframes`
	0% {
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
`;

const enterFromBottom = keyframes`
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
`;

export const Section = styled.section`
	background-color: ${MyTheme.bgColor.section};
	border-radius: 5px;
	/* padding: 5px; */
	/* margin: 5px 5px 0px 5px; */
	text-align: left;
	/* overflow: hidden; */
	animation: ${enterFromBottom} 1s ease-in-out;
`;
// export const Section = styled.section`
//   background-color: ${MyTheme.bgColor.section};
//   border-radius: 5px;
//   padding: 5px;
//   margin: 5px 5px 0px 5px;
//   text-align: left;
//   animation: ${enterFromRight} 1s ease-out;
//     /* box-sizing: border-box; */

//   /* transform:
//     perspective(1500px)
//     rotateX(10deg)
//     scale(0.9);
//   box-shadow: 0px 20px 100px #555;
//   transition:0.5s ease all;

//   &:hover {
//     transform:
//       rotate(0deg)
//       scale(1)
//       translateY(10px);
//   } */
// `;

export const Gap = styled.div`   
	height: ${props => props.px + 'px'};
	width: 100%;
`

const BlurIn = keyframes`
	0% {
		opacity: 0;
		filter: blur(5px);
	}
	33% {
		opacity: 1;
		filter: blur(5px);
	}
	100% {
		opacity: 1;
		filter: blur(0);
	}
`;

export const H3 = styled.h1`
	font-size: 1.2em;
	font-size: calc(1.0em + 0.3vw);
	color: #fbdbb6;
	margin: 0;
	padding: 5px;

	animation-name: ${BlurIn};
	animation-duration: 2.5s;
	animation-direction: forwards;

`

export const Text = styled.p`
	font-size: 1em;
	margin: 0;
	padding: 0px 15px;
	color: ${MyTheme.color.text};
	text-shadow: 1px 1px 10px ${MyTheme.shadow.text};
`

export const TextArea = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    margin: auto;
    border: none;
    padding: 30px 10px;
    text-align: center;
    border-radius: 10px;
`

export const SubSectionContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 15px;
  text-align: left;
`

export const Select = styled.select`
	/* height: 20px; */
	background-color: ${MyTheme.bgColor.select};
	color: white;
`

const animationShake = keyframes`
	0% { opacity: 1; transform: translateX(0px) ;}
	5% { opacity: 1; transform: translateX(-10px); }
	10% { opacity: 1; transform: translateX(10px); }
	15% { opacity: 1; transform: translateX(-10px); }
	20%, 100% { opacity: 1; transform: translateX(0px); }
`

export const Bounce = styled.div`
	text-align: right;
	margin-right: 5px;
	font-size: 80%;
	color: 1px 1px 10px ${MyTheme.color.bounce};
	text-shadow: 1px 1px 10px ${MyTheme.shadow.bounce};


	animation-name: ${animationShake};
	animation-duration: 4s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;

`

const animationSwipe = keyframes`
	0% { opacity: 0; transform: rotate(0deg); }
	20% { opacity: 0.5; transform: rotate(0) ;}
	25% { transform: rotate(-30deg); }
	30% { transform: rotate(30deg); }
	35% { transform: rotate(-30deg); }
	40%, 60% { opacity: 0.5; transform: rotate(0deg); }
	100% { opacity: 0; transform: rotate(0deg); }
  
`

export const SwipeIcon= styled(MdOutlineSwipe)`
	position: absolute;
	font-size: 4vw;
	top: 0;
	right: 0;
	padding: 10px;

	opacity: 0;
	animation-delay: 2s;
	animation-name: ${animationSwipe};
	animation-duration: 10s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;

`

// components for Control Panel


export const Arrow = styled.div`
	position: absolute;
	top: 60px;
	right: 15px;
	cursor: pointer;
	z-index: 10;
	background-color: #414455;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before,
	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 10px;
		height: 2px;
		background-color: #ffffff;
		transform-origin: center;
		transition: transform 0.3s;
	}

	&:before {
		transform: rotate(0deg);
	}

	&:after {
		${(props) => props.open ? `transform: rotate(0deg);` : `transform: rotate(90deg);`}
	}
`;

export const ParentContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100vh;
	/* height: calc(100vh - 65px); */
`;

// Styled-components for the containers
export const PanelFlexContainer = styled.div`
	position: absolute;
	top: 50px;
	right: 5px;
	display: ${(props) => (props.open ? "flex" : "none")};
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
	/* height: 100%; */
	/* max-height: 600px; */
	/* min-width: 160px; */
	width: 300px;
	padding: 16px;
	/* border: 1px solid #ccc; */
	border-radius: 5px;
	background-color: ${MyTheme.bgColor.section};
	/* box-shadow: 0 0 10px 5px rgb(0, 0, 0); */

	z-index:9;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const PanelTabButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	background-color: ${MyTheme.bgColor.page};
	
`;

export const PanelTabButton = styled.button`
	flex: 1;
	height: 50px;
	cursor: pointer;
	color: ${MyTheme.color.button};
	border: none;
	/* border-radius: 0; */
	
	&:first-child {
		border-top-left-radius: 4px;
	}
	
	&:last-child {
		border-top-right-radius: 4px;
	}
	background-color: ${({ active }) => (active ? MyTheme.bgColor.page : 'grey')};
	
`;

export const PanelTitle = styled.h3`
	margin: 0;
	margin-bottom: 8px;
	font-size: 1.2em;
`;

export const PanelGroupContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 16px;
	width: 100%;
`;

export const PanelInputContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 16px; // Increase the space between inputs
	/* border: 1px solid #ffffff; */
	box-sizing: border-box;
	
	border-radius: 0px 0px 4px 4px;
	padding: 12px 4px; // Add more space between the top of the border and the input
	width: 100%;
	/* background-color: rgba(0,0,0, 0.9); */
	background-color: ${MyTheme.bgColor.page};
`;

export const PanelGroupTitle = styled.h3`
	margin: 0;
	margin-bottom: 8px;
`;

export const PanelInputLabel = styled.label`
	position: absolute;
	top: -10px;
	left: 8px;
	padding: 0 4px;
	font-size: 14px;
	background-color: ${MyTheme.bgColor.sectionSolid};
`;

export const PanelInput = styled.input`
	flex: 1;
	margin-top: 4px; // Add more space between the label and the input
`;

export const PanelInputColor = styled.input.attrs(() => ({
	type: 'color',
  }))`
	width: 100%;
	height: 40px;
  `;

export const PanelButton = styled.button`
	color: ${MyTheme.color.text};
	background-color: black;
	flex: 1;
	margin-top: 4px; // Add more space between the label and the input
`;
