/* eslint-disable */

import React, {useState, useRef} from 'react';
import styled, { keyframes } from 'styled-components'

import { Section, Gap, MyTheme, PageContainer } from '../components/style/MyTags';
// import AnimateHeading from '../components/animation/AnimateHeading';

import MandalaAudioVisualiser from '../components/visualiser/MandalaAudioVisualiser';
// import MandalaAudioVisualiser from '../components/visualiser/MandalaAudioVisualiserArray';
// import FrequencyVisualiser from '../components/visualiser/FrequencyVisualiser';

export default function Visualiser() {

	const gap = 10;

  return (
	<PageContainer>

		<Section style={{padding: '5px 0px', backgroundColor: 'black'}}>
			{/* <AnimateHeading text='Mandala Visualiser' /> */}
			{/* <SphereHolder> */}
				<MandalaAudioVisualiser />
			{/* </SphereHolder> */}
		</Section>
		{/* <Gap px={gap}/> */}

		{/* <Section style={{padding: '5px 0px', backgroundColor: 'black'}}>
			<AnimateHeading text='Frequency Visualiser' />
			<CanvasHolder>
				<FrequencyVisualiser /> 
			</CanvasHolder>
		</Section>
		<Gap px={gap}/> */}

	</ PageContainer>
  );
}

const CanvasHolder= styled.div`
	display: flex;
	justify-content: center;
`
const SphereHolder = styled.div`
	height: calc(100vh - 65px);
	/* width: 80vw; */
	/* max-height: 600px; */
`;