import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select, MyTheme} from '../style/MyTags';
import { Arrow, ParentContainer, PanelFlexContainer, PanelGroupContainer, PanelInputContainer, PanelGroupTitle, PanelInputLabel, PanelInput, PanelInputColor, PanelButton } from '../style/MyTags';
import Sketch from "react-p5";
import p5 from 'p5';
import { abs, floor, random, round, sign} from 'mathjs';
import song from '../../audio/OverdueNolai.mp3'
import { AudioAnalyzer } from './AudioAnalyser'
//INPUTS---------------------------------------------------------------
let sceneList = ['default','infiniteZoom', 'demoLoop','alphaOnly','shadowOnly']
let scene = 'infiniteZoom'
let musicSources = ['microphone', 'inbuilt']
let musicSource = 'microphone'
let shadowTypes = ['none','global','layer']
let shadowType = 'global'
let shadowColour = 'black'
let shadowBlur = 30
let stroke = false; //1 for complex see-through, 2 for solid
let fr = 30; //framerate 24
let framecount = 0;
let chance = 0.1; //chance in 10 of reversal 0.1
let enable = {hue: true, sat: false, brtn: false, alpha: true} //change properties randomly or not
let enableReact = {alpha: true, wave: true, shift: true}
let orderList = ['normal','reverse']
let order = 'normal'
let rotate = false
let rotateSpeed = 0.25
let rotateAng = 0
let zoom = false
let zoomCount = 0
let scaleGlobal = 0.8 //0.8 //scales the size of all the layers
let nPoints = {default: 4, current: 5, min: 3, max: 10}
let petals = {default: 10, current: 10, min: 4, max: 15}
let layers = {default: 5, current: 10, min: 5, max: 15}
let hue =  {on: true, min: 0, max: 360, default: 0, current: 0, increment:0.5} //rate of color change 1.4
let sat =  {on: false, min: 0, max: 100, default: 100, current: 100, increment:2}
let brtn =  {on: false, min: 30, max: 100, default: 100, current: 100, increment:0.2}
let alpha =  {on: false, min: 35, max: 100, default: 100, current: 100, increment:0.1}
let posRate =  {min: 0, max: 10, default: 0.5, current: 0.5, increment:0.01}
let strokeW =  {min: 0, max: 10, default: 0, current: 0, increment:0.1}
//AUDIO ANALYSIS-----------------------------------------------------
let audioAnalyser
let analyseMode = 'max'
let gotMedia = false
let dance = false
let freqAverages
let freqAverage

//INITIALISATION---------------------------------------------------
let myP5
let states = []
let statesBackup = []
let paused = false;
let ang
let canvas
let maxSize
let rMax

class LayerInit {
	constructor() {
		this.r = 0;
		this.rMax = 0;
		this.nPoints = round(random(nPoints.min, nPoints.max))
		this.posD = 0.5
		this.x = Array(nPoints.current).fill(0);
		this.y = Array(nPoints.current).fill(0);
		this.xD = Array(nPoints.current).fill(this.posD);
		this.yD = Array(nPoints.current).fill(this.posD);
		this.xMax = Array(nPoints.current).fill(0);
		this.yMax = Array(nPoints.current).fill(0);
		this.xMin = Array(nPoints.current).fill(0);
		this.yMin = Array(nPoints.current).fill(0);
		this.prop = {
			hue: JSON.parse(JSON.stringify(hue)),
			sat: JSON.parse(JSON.stringify(sat)),
			brtn: JSON.parse(JSON.stringify(brtn)),
			alpha: JSON.parse(JSON.stringify(alpha)),
		};
		this.randXY = { 'x': floor(random(this.x.length)), 'y': floor(random(1,this.y.length-1))};
		this.randXYScale =[random(1), random(1)];
		this.scale = { current: 1, increment: 0.01, min: 0, max: 2}
		if(this.prop.hue.on) this.prop.hue.current = random(this.prop.hue.min, this.prop.hue.max)
		if(this.prop.sat.on) this.prop.sat.current = random(this.prop.sat.min, this.prop.sat.max)
		if(this.prop.brtn.on) this.prop.brtn.current = random(this.prop.brtn.min, this.prop.brtn.max)
		if(this.prop.alpha.on) this.prop.alpha.current = random(this.prop.alpha.min, this.prop.alpha.max)
	}

	setLimits(radius){
		// numbers in terms of layer radius
		let xMin = 0.35
		let xMax = 0.99
		let yMin = 0.1 //0.06
		let yWidthScaler = 0.5 // originally 0.9
		let spacing = (xMax - xMin) / nPoints.current

		for (let i = 0; i < nPoints.current; i++) {
			if (i===0) { // first boundary case
				this.xMin[i] = xMin * radius
				this.xMax[i] = (xMin + spacing) * radius
				this.yMin[i] = 0
				this.yMax[i] = 0
			} else if (i === (nPoints.current - 1)) { // last boundary case
				this.xMax[i] = xMax * radius
				this.xMin[i] = (xMax - spacing) * radius
				this.yMax[i] = 0
				this.yMin[i] = 0
			} else {//rest of the points in between
				this.xMin[i] = (xMin + spacing * i) * radius
				this.xMax[i] = (xMin + spacing * (i + 1)) * radius
				this.yMax[i] = this.xMax[i] * myP5.tan(ang) * yWidthScaler
				this.yMin[i] = yMin * radius
			}
			this.x[i] = random(this.xMin[i], this.xMax[i])
			this.y[i] = random(this.yMin[i], this.yMax[i])
		}
	}
}
let state = new LayerInit()
let clicked = false
let firstArt = true

// creates initial state array
const newArt = () => {

	states = [];
	if (firstArt === false){
		layers.current = round(random(layers.min, layers.max))
		petals.current = round(random(petals.min, petals.max))
	}
	if(audioAnalyser !== undefined) audioAnalyser.updateParameters(layers.current)
	firstArt = false
	ang = 360 / petals.current;
	console.log(`layer:${layers.current}, petals:${petals.current}`)
	rMax = (myP5.width / 2) * scaleGlobal
	for (let j = layers.current; j > 0; j--) {
		let radius = Math.pow((j / layers.current), 1.5)  * (myP5.width / 2) * scaleGlobal
		// let radius = (j / layers.current)  * (myP5.width / 2) * scaleGlobal
		let stateInit = createNewLayer(radius)
		states.push(stateInit)
	}

	console.log(states)
	
	if (paused){myP5.draw();}

	if (order === 'reverse') {states.reverse()}

}

const createNewLayer = (radius) => {
	let stateInit = new LayerInit()
	stateInit.r = radius
	stateInit.scale.current = (stateInit.r / rMax) * (stateInit.scale.max - stateInit.scale.min)
	console.log(stateInit.r, stateInit.scale.current)
	// stateInit.setLimits(rMax)
	stateInit.setLimits(radius)
	return stateInit
}

const audioReactToggle = () => {
	if(clicked) {
		musicState()
	} else {
		clicked = true
		// dance = false
		setAudio()
		musicState()
	}
}

function MandalaP5() {

	const setup = (p, canvasParentRef) => { 
		myP5 = p;
		maxSize = p.min(p.windowWidth,p.windowHeight-70)
		// console.log(p.canvasParentRef, p.canvasParentRef)
		// maxSize = p.min(p.canvasParentRef, p.canvasParentRef)
		p.frameRate(fr)
		canvas = p.createCanvas(maxSize, maxSize).parent(canvasParentRef)
		p.background(0);
		p.angleMode(p.DEGREES);
		p.colorMode(p.HSB, 360, 100, 100, 100);

		strokeW.max = round(maxSize / 100)
		if (stroke) {p.stroke(0);} else {p.noStroke(0);}

		newArt()

		canvas.mouseClicked(() => {
			audioReactToggle()
		})

		// p.keyTyped = () => {
		// 	if (p.key === 'n') {
		// 		newArt()
		// 		audioAnalyser.updateParameters(layers.current)
		// 	}
		// }
	}

	const sceneInfiniteZoom = () => {

	}

	//main draw function which gets called every frame
	const draw = (p) => {

		//gets audio data for each frame
		if(dance && gotMedia) {freqAverages = audioAnalyser.getFrequency(analyseMode)}

		p.translate(p.width / 2, p.height / 2);
		// p.background(p.color(0,0,0,0.99)); //leaves a slight trace (trippy)
		p.background(p.color(0,0,0)); //needed every frame or shapes leave a trace
		p.rotate(rotateAng)
		if(rotate) {
			rotateAng = rotateSpeed * framecount
			framecount++
		}
		
		if (scene === 'default') {sceneDefault()}
		else if (scene === 'infiniteZoom') {
			sceneInfiniteZoom()
		}
		
		// global shadow configuration (done before the layers loop to prevent redefining)
		if (shadowType === 'global') {
			p.drawingContext.shadowBlur = shadowBlur
			p.drawingContext.shadowColor = p.color(shadowColour)
		} else if (shadowType === 'none') {
			p.drawingContext.shadowBlur = 0
		} else {p.drawingContext.shadowBlur = shadowBlur} //this is done for layer specific shadowing
		
		zoomCount += 0.001
		
		//for each layer
		for (let iLayer = 0; iLayer < layers.current; iLayer++) {
			p.push();

			// gets the state for each layer
			state = states[iLayer]
			// if (frameCount===1) console.log(state)
			let prop = state.prop

			// state.r = Math.pow(((layers.current - iLayer) / layers.current), 1.5)  * (myP5.width / 2) * scaleGlobal
			let radius = (1 / layers.current)  * (myP5.width / 2) * scaleGlobal

			// morph positions randomly for each point of the petal
			if(!dance) {
				for(let i = 0; i < nPoints.current; i++){
					// morph
					state.x[i] += state.xD[i]
					if (state.x[i] < state.xMin[i] || state.x[i] > state.xMax[i] || random(10) < chance){
						state.xD[i] *= -1;
					}
					if (i === 0 || i === (nPoints.current -1)){
						state.y[i] = 0
					} else {
						state.y[i] += state.yD[i]
						if (state.y[i] < state.yMin[i] || state.y[i] > state.yMax[i] || random(10) < chance){
							state.yD[i] *= -1;
						}
					}
				}
			}

			if (!dance) {
				if(enable.hue) incrementValue(prop.hue, 'loop')//hue changes
				if(enable.sat) incrementValue(prop.sat, 'bounce') //saturation changes
				if(enable.brtn) incrementValue(prop.brtn, 'bounce') //brightness changes
				if(enable.alpha) incrementValue(prop.alpha, 'bounce') //alpha changes
			}

			if(zoom) {
				// incrementValue(state.scale, 'bounce', false)
				state.scale.current += 0.01
				// if(iLayer === 0) console.log(round(state.scale.current, 3))
				if(state.scale.current >= state.scale.max) {
					// console.log(states)
					// console.log(state)
					state.scale.current = state.scale.min
					// states.splice(iLayer, 1)
					// let newLayer = createNewLayer(radius)
					// layers.current += 1
					// states.push(newLayer)
					// states.unshift(newLayer)

					// console.log(newLayer)
					console.log(states)

				}
			}

			p.pop(); // Restore the previous transformation state
			p.push(); // Save the current transformation state

			// p.scale(round(state.scale.current + zoomCount, 10))
			p.scale(state.scale.current)

			// console.log(states)

			statesBackup[iLayer] = JSON.parse(JSON.stringify(state)) //need to fix how this works

			// REACT TO AUDIO
			if(dance && gotMedia) {
				freqAverage = freqAverages[iLayer]
				if(enableReact.alpha) prop.alpha.current = round(audioAnalyser.normaliseVal(freqAverage, 255, 0, prop.alpha.max, 'log', audioAnalyser.amplifiers[iLayer], iLayer))
				// state.x[state.randXY['x']] = round(audioAnalyser.normaliseVal(freqAverage, 255, 0, state.r * state.randXYScale[0], 'log'))

				if(enableReact.wave) state.y[state.randXY['y']] = round(audioAnalyser.normaliseVal(freqAverage, 255, 0, state.r * state.randXYScale[1], 'none'))
				
				if(enableReact.shift) {
					let maxShift = ((layers.current - iLayer) / layers.current) * (p.width / 2) * (1 - scaleGlobal)
					let shift = round(audioAnalyser.normaliseVal(freqAverage, 255, 0, maxShift, 'none'))
					for (let i = 0; i < state.x.length; i++) {state.x[i] += shift}
				}
			}
			sat.current = prop.sat.current
			brtn.current = prop.brtn.current
			alpha.current = prop.alpha.current
			hue.current = prop.hue.current //left out since it never global

			p.fill(round(hue.current), round(sat.current), round(brtn.current), alpha.current)

			// shadow configuration
			if(shadowType === 'layer'){
				p.drawingContext.shadowBlur = shadowBlur
				let sColour = `hsba(${round(hue.current)}, ${sat['max']}%, ${brtn['max']}%, ${alpha['current']/100})`;
				p.drawingContext.shadowColor = p.color(sColour)
			}

			// p.pop(); // Restore the previous transformation state
			// p.push(); // Save the current transformation state

			//draws each petal for layer
			for (let i = 0; i < petals.current; i++) {

				let nPoints = state.x.length - 1
				p.beginShape()
				for (let iPoint = nPoints; iPoint >= -nPoints; iPoint--) {
					let absPoint = abs(iPoint)
					if (iPoint === nPoints || iPoint === -nPoints) {
						for (let iRepeat = 0; iRepeat < 2; iRepeat++){
							p.curveVertex(state.x[absPoint], sign(iPoint) * state.y[absPoint]);
						}
					} else if (nPoints === 0) {
						p.curveVertex(state.x[iPoint], state.y[iPoint]);
					} else  {
						p.curveVertex(state.x[absPoint], sign(iPoint) * state.y[absPoint]);
					}
				}
				p.endShape()
				p.rotate(ang); //rotate within each layer
			}
			p.rotate((ang / 2) * iLayer); // rotates each layer
			p.pop()
		} // end of layer loop
		states = JSON.parse(JSON.stringify(statesBackup))
		console.log(states[0].scale.current, states[4].scale.current)
	} // end of draw function


	//SCENES------------------------------------------------------------
	const sceneDefault = () => {
		sat.current = sat.default
		brtn.current = brtn.default
		alpha.current  = alpha.default
	}
	//END SCENES--------------------------------------------------------

	function incrementValue(property, mode='bounce', randomReverse=true) {
		/* LFO occilator */
		if (mode === "loop") {
			property.current += property.increment;
			if (property.current > property.max) {
				property.current = property.min;
			} else if (property.current < property.min){
				property.current = property.max;
			}
		} else if (mode === "bounce") {
			property.current += property.increment;
			if (property.current >= property.max || property.current <= property.min) {
				property.increment *= -1;
			}
			if (property.current > property.max) property.current = property.max
			if (property.current < property.min) property.current = property.min
		}
		if(randomReverse){
			if (random(10) < chance) {
				property.increment *= -1;
			}
		}
		return property.current;
	}
	return <Sketch setup={setup} draw={draw} />
}


const resetToDefault = () => {
	brtn.current = brtn.default
	alpha.current = alpha.default
	sat.current = sat.default
	for (let i = 0; i < states.length; i++) {
		states[i].prop.brtn.current = states[i].prop.brtn.default;
		states[i].prop.alpha.current = states[i].prop.alpha.default;
		states[i].prop.sat.current = states[i].prop.sat.default;
	}
}

const musicState = () => {
	if(musicSource === 'inbuilt'){
		if(dance){
			audioAnalyser.pauseAudio()
			resetToDefault()
		} else {audioAnalyser.playAudio()}
	} else {resetToDefault()}
	dance = !dance
}

const setAudio = () => {
	audioAnalyser = new AudioAnalyzer(layers.current, analyseMode)
	gotMedia = false
	audioAnalyser.getAudio(musicSource, song)
	.then(data => {
		gotMedia = data.gotMedia; // true if media was successfully captured, false otherwise
	})
	.catch(error => {
		console.error('Error: ', error);
	});
}

const isValidColor = (colorString) => {
	try {
		const p5Instance = new p5();
		p5Instance.color(colorString);
		//   console.log('isColour')
		return true;
	} catch (error) {
			// console.log('notColour')
		return false;
	}
  };

export default function MandalaAudioVisualiser() {

	const [selectedScene, setSelectedScene] = useState(scene);
	const [selectedMusicSource, setSelectedMusicSource] = useState(musicSource);
	const [selectedShadow, setSelectedShadow] = useState(shadowType);
	const [selectedOrder, setSelectedOrder] = useState(order);
	const [selectedRotate, setSelectedRotate] = useState(rotate);
	const [selectedShadowColour, setSelectedShadowColour] = useState(shadowColour);
	const [isControlPanelOpen, setIsControlPanelOpen] = useState(false);
	const [reactParams, setReactParams] = useState({alpha: true, wave: true, shift: true});
	

	useEffect(() => {
		setSelectedScene(scene)
		setSelectedMusicSource(musicSource)
		setSelectedShadow(shadowType)
		resetToDefault()
	},[selectedScene,selectedMusicSource,selectedShadow])

	/* All handleClick functions */

	function handleSceneChange(event) {
		scene = event.target.value
		setSelectedScene(scene)
		if(scene === 'alphaOnly') alpha.current = 0
		else if (scene === 'infiniteZoom'){
			if (order === 'normal'){
				states.reverse()
			}
			order = 'reverse'
		}
	}

	function handleMusSrcChange(event) {
		musicSource = event.target.value
		console.log(musicSource)
		if (musicSource === 'microphone') {audioAnalyser.pauseAudio()}
		setSelectedMusicSource(musicSource);
		clicked = false
		dance = false
	}

	function handleShadowChange(event) {
		shadowType = event.target.value
		console.log(event.target.value)
		setSelectedShadow(shadowType);
	}

	function handleOrder(event) {
		order = event.target.value
		console.log(event.target.value)
		setSelectedOrder(order);
		states.reverse()
	}

	function handleRotate(event) {
		rotate = !rotate
		console.log(event.target.id)
		setSelectedRotate(rotate);
	}
	function handleReactParams(event) {
		let propertyName = event.target.id
		enableReact[propertyName] = !enableReact[propertyName]
		console.log(enableReact)
		setReactParams(prevState => ({
			...prevState,
			[propertyName]: !prevState[propertyName]
		}));

	}

	function handleShadowColour(event) {
		if(isValidColor(event.target.value)){
			shadowColour = event.target.value
			console.log(event.target.value)
			setSelectedShadowColour(shadowColour);
		}
	}

	const DropDownSelect = (props) => {
		return (
			<Select value={props.val} onChange={props.func}>
				{props.array.map((option, index) => (
				<option key={index} value={option}>
					{option}
				</option>
				))}
			</Select>
		)
	}

	const handleClickNewArt = () => {
		newArt()
		
	}

	return (
		<>
			<ParentContainer>
				<div style={{display: 'flex', justifyContent: 'center', height: '100%', width: '100%', margin: 'none'}}>
					<MandalaP5 />
				</div>
				<Arrow
					open={isControlPanelOpen}
					onClick={() => setIsControlPanelOpen(!isControlPanelOpen)}
				/>
				<PanelFlexContainer open={isControlPanelOpen}>
					<PanelGroupContainer>
						<PanelGroupTitle>General</PanelGroupTitle>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="newArt" style={{ marginRight: '2px' }}>Generate New Art</PanelInputLabel>
							<PanelButton id='newArt' onClick={handleClickNewArt}>Generate</PanelButton>
						</PanelInputContainer>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="scene">Scene</PanelInputLabel>
							<DropDownSelect id='scene' val={selectedScene} array={sceneList} func={handleSceneChange}/>
						</PanelInputContainer>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="audioSource">Audio Source</PanelInputLabel>
							<DropDownSelect id='audioSource' val={selectedMusicSource} array={musicSources} func={handleMusSrcChange}/>
						</PanelInputContainer>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="order">Order</PanelInputLabel>
							<DropDownSelect id='order' val={selectedOrder} array={orderList} func={handleOrder}/>
						</PanelInputContainer>
					</PanelGroupContainer>

					<PanelGroupContainer>
						<PanelGroupTitle>Shadowing</PanelGroupTitle>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="shadowType">Shadow Type</PanelInputLabel>
							<DropDownSelect id="shadowType" val={selectedShadow} array={shadowTypes} func={handleShadowChange}/>
						</PanelInputContainer>

						{ shadowType === 'global' ?
							(<PanelInputContainer>
								<PanelInputLabel htmlFor="shadowColour">Shadow Colour</PanelInputLabel>
								{/* <PanelInput id="shadowColour" type="text" value={selectedShadowColour} onChange={handleShadowColour} /> */}
								<PanelInputColor
									value={selectedShadowColour}
									onChange={handleShadowColour}
								/>
							</PanelInputContainer>
							) : ''
						}
					</PanelGroupContainer>

					<PanelGroupContainer>
						<PanelGroupTitle>Movement</PanelGroupTitle>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="movement">React</PanelInputLabel>
							<PanelButton onClick={()=>{
								audioReactToggle()
								// setReact(dance)
								}} id="movement">React to audio</PanelButton>
						</PanelInputContainer>

							
						<PanelInputContainer>
							<PanelInputLabel htmlFor="alpha">Alpha</PanelInputLabel>
							<PanelInput
							id="alpha"
							type="checkbox"
							checked={reactParams.alpha}
							onChange={(e) => handleReactParams(e)}
							/>
						</PanelInputContainer>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="wave">Morph Petal</PanelInputLabel>
							<PanelInput
							id="wave"
							type="checkbox"
							checked={reactParams.wave}
							onChange={(e) => handleReactParams(e)}
							/>
						</PanelInputContainer>
						<PanelInputContainer>
							<PanelInputLabel htmlFor="shift">Shift</PanelInputLabel>
							<PanelInput
							id="shift"
							type="checkbox"
							checked={reactParams.shift}
							onChange={(e) => handleReactParams(e)}
							/>
						</PanelInputContainer>

						
						<PanelInputContainer>
							<PanelInputLabel htmlFor="rotate">Rotate</PanelInputLabel>
							<PanelInput
								id="rotate"
								type="checkbox"
								checked={selectedRotate}
								onChange={(e) => handleRotate(e)}
							/>
						</PanelInputContainer>
					</PanelGroupContainer>

				</PanelFlexContainer>
			</ParentContainer>
		</>
	)
}
