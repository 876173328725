import React from 'react';
import styled from 'styled-components';
import {MyTheme} from '../style/MyTags'

const ChatComponent = ({ chatData }) => {
    return (
        <ChatContainer>
        {
            chatData.map((chat, index) => (
                <MessageContainer key={index} user={chat.user}>
                    <Message user={chat.user}>{chat.message}</Message>
                    <User>{chat.user}</User>
                </MessageContainer>
            ))
        }
        </ChatContainer>
    );
}

export default ChatComponent;


const ChatContainer = styled.div`
    flex: 1;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 20px;
    overflow-y: auto;
    background-color: ${MyTheme.bgColor.section};
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.user === 'AI' ? 'flex-start' : 'flex-end'};
    margin-bottom: 20px;
`;

const Message = styled.div`
    max-width: 60%;
    word-break: break-word;
    background-color: ${props => props.user === 'AI' ? '#e0e0e0' : '#4c8bf5'};
    color: ${props => props.user === 'AI' ? '#000' : '#fff'};
    padding: 10px;
    border-radius: 10px;
    border-bottom-left-radius: ${props => props.user === 'AI' ? '0' : '10px'};
    border-bottom-right-radius: ${props => props.user === 'AI' ? '10px' : '0'};
`;

const User = styled.span`
    font-weight: bold;
    font-size: 0.8em;
    color: #888;
    margin-top: 5px;
`;

const ChatMessage = styled.span`
    margin-left: 10px;
`;

